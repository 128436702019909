import React, { useContext, useEffect, useState } from 'react';
import i18n from '../../../utilities/i18n';
import { useSelector } from 'react-redux';
import AsyncTypeInput from '../../commons/input/AsyncTypeHead/AsyncTypeInput';
import { useDispatch } from 'react-redux';
import { listPatientClaims, listPatientDropdownData, listProcedureClaims, searchClaimIDS } from './StateManagement/asyncThunkAPI';
import { hideModalWindow, onHandleAdvanceSearchFields, onHandleSearchClaimDateChange, onHandleValueChange, resetPatientDropdownSearch, resetSearchVariable, updateClaimSearchVariables } from './StateManagement/paymentSlice';
import LoadingContext from '../../../container/loadingContext';
import { Divider } from '@mui/material';
import CommonButton from '../../commons/Buttons';
import SelectInput from '../../commons/input/SelectInput';
import { CLAIM_TYPES, RESPONSIBILITY_TYPES } from '../../../utilities/dictionaryConstants';
import { CLAIMDATEOFSERVICERANGE } from '../../../utilities/staticConfigs';
import CalendarInput from '../../commons/input/CalendarInput';
import { CommonDateFormat, currencyFormat } from '../../../utilities/commonUtilities';
import { Link } from 'react-router-dom';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import TextInput from '../../commons/input/input';
import { claimListHeadings } from '../InsuranceTableHeading';
const DROP_CHARGES_DATA = [
    { 'id': 'charges', 'name': i18n.t("payments.post_payments.chargesWithBalance") },
    { 'id': 'all', 'name': i18n.t("payments.post_payments.allCharges") }
]
const CLAIM_SEARCH_OPTIONS = [
    { id: "claim_pk", name: "Claim" },
    { id: "patient_pk", name: "Patient" },
    { id: "provider_pk", name: "Provider" },
    { id: "payer_pk", name: "Payer" },
];
const defaultSearchOption = "claim_pk";
const ClaimSearchComponent = () => {
    const dispatch = useDispatch();
    const setShowLoadingBar = useContext(LoadingContext)
    const { claim_id } = useSelector((state) => state.paymentData.claimSearchVariables);
    const { masterClaims, selectedClaimVals, searchClaimLoading, patientDropdown, claimSearchVariables, claimStatusList,
        selectCharge, searchModalOpen, header, searchText, searchOption, patientClaimsData,searchQuery
    } = useSelector((state) => state.paymentData);
    const [showResetButton, setShowResetButton] = useState(false);

    /**
     * useEffect inovke when claim id search completed;
     */
    useEffect(() => {
        setShowLoadingBar(searchClaimLoading);
    }, [searchClaimLoading])

    /**
     * function to search claim id 
     */
    const onSearchClaimID = (query) => {
        dispatch(searchClaimIDS(query))
    }

    /**
     * search patient claims
     * @param {*} query 
     */
    const listPatientDropdown = (query) => {
        dispatch(listPatientDropdownData(query))
    }

    const onHandleClaimIDChange = (e) => {
        if (e[0]) {
            dispatch(updateClaimSearchVariables(e[0].id));
            dispatch(listProcedureClaims(e[0].id))
        }
        else {
            dispatch(updateClaimSearchVariables(""))
        }
    }



    const onHandleSearchClaim = (e) => {
        if (e[0]) {
            dispatch(listPatientClaims({ type: "patient", value: e[0].id }))
            dispatch(resetPatientDropdownSearch([]));
        }
    }

    const showAdvancedOption = () => {
        setShowResetButton(!showResetButton)
        document.getElementById("hidingAdvancedOption").classList.toggle("show");
        if (
            document.getElementById("hidingAdvancedOptionSearch").style.display ==
            "none"
        ) {
            document.getElementById("hidingAdvancedOptionSearch").style.display = "";
        } else {
            document.getElementById("hidingAdvancedOptionSearch").style.display =
                "none";
        }
    }

    const onHandleSearchClaimDate = (value, field) => {
        dispatch(onHandleSearchClaimDateChange({ key: field, value: value }))
    }

    const onHandleAdvSearchValue = (e)=> {
        const {name , value } = e.target;
        dispatch(onHandleAdvanceSearchFields({name,value}))
    }

    const onHandleSearchValueChange = (e) => {
        const { name, value } = e.target;
        if (name === "selectCharge") {
            dispatch(onHandleValueChange({ name, value }));
        }
    };

    const onReset = () => {
        dispatch(resetSearchVariable())
    }

    const openSearchModal = () => {
        dispatch(listPatientClaims({ type: "advanced", value: "" }))
    }


    const resetFormSearch = () => {
        dispatch(hideModalWindow({ key: "searchModalOpen", open: false }))
    }

    const onHandleChangeSearchOption = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "searchOption") dispatch(onHandleValueChange({ name: "searchOption", value: value }))
        else dispatch(onHandleValueChange({ name: "searchText", value: value }))
    }

    const onClickSearchClaim = () => {
        let query = searchQuery;
        query += "&search_option=" + searchOption + "&search_text=" + searchText;
        dispatch(listPatientClaims({ type: "searchInAdvanced", value: query}))
    }

    const postClaim = (e, id) => {
        dispatch(listProcedureClaims(id))
        resetFormSearch();
    }

        

    return (
        <>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
                <div className="margin-top20">
                    <div className="row">
                        <div className="col pl-3">
                            <div className="font-bold div-inline">
                                {i18n.t(
                                    "payments.post_payments.insurancePayment.selectClaim"
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row  padding-top10">
                        <div className="col-3 form-group  padding-top15">
                            <AsyncTypeInput
                                id="searchByClaimId"
                                labelKey="name"
                                minLength={0}
                                // onChange={setPatientList}
                                options={masterClaims}
                                onSearch={(query) => onSearchClaimID(query)}
                                name="selectClaim"
                                value={claim_id}
                                onValueChange={(e) => onHandleClaimIDChange(e)}
                                selected={selectedClaimVals}
                                label={i18n.t(
                                    "payments.post_payments.insurancePayment.searchByClaimId"
                                )}
                            />
                        </div>
                        <div className="col-3 padding-top15">
                            <AsyncTypeInput
                                id={"insurancePaymentPatient"}
                                name="searchPatient"
                                labelKey={"name"}
                                minLength={2}
                                options={patientDropdown}
                                onSearch={(query) => listPatientDropdown(query)}
                                onValueChange={(e) => onHandleSearchClaim(e)}
                                selected={[]}
                                label={i18n.t(
                                    "payments.post_payments.insurancePayment.searchPatient"
                                )}
                            />
                        </div>
                        <div className="col-3 padding-top40">
                            <CommonButton
                                icon="advanced"
                                variant="outlined"
                                onClick={() => showAdvancedOption()}
                                label={i18n.t("commons.advancedSearch")}
                            />
                        </div>
                    </div>
                    <div className="hidingView" id="hidingAdvancedOption">
                        <div className="row  margin-top10">
                            <div className="col-3">
                                <SelectInput
                                    data={claimStatusList}
                                    name="claim_status"
                                    value={claimSearchVariables.claim_status}
                                    onValueChange={(e)=> onHandleAdvSearchValue(e)}
                                    label={i18n.t(
                                        "payments.post_payments.insurancePayment.claimStatus"
                                    )}
                                />
                            </div>
                            <div className="col-3">
                                <SelectInput
                                    name="responsibility_type"
                                    data={RESPONSIBILITY_TYPES}
                                    value={claimSearchVariables.responsibility_type}
                                    onValueChange={(e)=> onHandleAdvSearchValue(e)}
                                    label={i18n.t(
                                        "payments.post_payments.insurancePayment.responsibilityType"
                                    )}
                                />
                            </div>
                            <div className="col-3">
                                <SelectInput
                                    name="claim_type"
                                    data={CLAIM_TYPES}
                                    value={claimSearchVariables.claim_type}
                                    onValueChange={(e)=> onHandleAdvSearchValue(e)}
                                    label={i18n.t(
                                        "payments.post_payments.insurancePayment.claimType"
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {i18n.t(
                                    "payments.post_payments.insurancePayment.claimDateRange"
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <SelectInput
                                    data={CLAIMDATEOFSERVICERANGE}
                                    name="claim_date_type"
                                    value={claimSearchVariables.claim_date_type}
                                    onValueChange={(e)=> onHandleAdvSearchValue(e)}
                                />
                            </div>
                            <div className="col-3" style={{ marginTop: -20 }}>
                                <CalendarInput
                                    name="claim_date_of_service_from"
                                    selected={
                                        claimSearchVariables.claim_date_of_service_from
                                    }
                                    maxDate={claimSearchVariables.claim_date_of_service_to ? claimSearchVariables.claim_date_of_service_to : new Date()}
                                    onValueChange={(e) =>
                                        onHandleSearchClaimDate(
                                            e,
                                            "claim_date_of_service_from"
                                        )
                                    }
                                />
                            </div>
                            <div className="col-3" style={{ marginTop: -20 }}>
                                <CalendarInput
                                    name="claim_date_of_service_to"
                                    selected={
                                        claimSearchVariables.claim_date_of_service_to
                                    }
                                    minDate={claimSearchVariables.claim_date_of_service_from ? claimSearchVariables.claim_date_of_service_from : new Date()}
                                    onValueChange={(e) =>
                                        onHandleSearchClaimDate(
                                            e,
                                            "claim_date_of_service_to"
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {i18n.t(
                                    "payments.post_payments.insurancePayment.claimSubmitRange"
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <SelectInput
                                    data={CLAIMDATEOFSERVICERANGE}
                                    name="claim_submitted_date_type"
                                    value={claimSearchVariables.claim_submitted_date_type}
                                    onValueChange={(e)=> onHandleAdvSearchValue(e)}
                                />
                            </div>
                            <div className="col-3" style={{ marginTop: -20 }}>
                                <CalendarInput
                                    name="claim_submitted_date_from"
                                    selected={
                                        claimSearchVariables.claim_submitted_date_from
                                    }
                                    maxDate={claimSearchVariables.claim_submitted_date_to ? claimSearchVariables.claim_submitted_date_to : new Date()}
                                    onValueChange={(e) =>
                                        onHandleSearchClaimDate(
                                            e,
                                            "claim_submitted_date_from"
                                        )
                                    }
                                />
                            </div>
                            <div className="col-3" style={{ marginTop: -20 }}>
                                <CalendarInput
                                    name="claim_submitted_date_to"
                                    selected={
                                        claimSearchVariables.claim_submitted_date_to
                                    }
                                    minDate={claimSearchVariables.claim_submitted_date_from ? claimSearchVariables.claim_submitted_date_from : new Date()}
                                    onValueChange={(e) =>
                                        onHandleSearchClaimDate(
                                            e,
                                            "claim_submitted_date_to"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <SelectInput
                                data={DROP_CHARGES_DATA}
                                selectOptionRemove={true}
                                onValueChange={(e) => onHandleSearchValueChange(e)}
                                name="selectCharge"
                                value={selectCharge}
                                label={i18n.t("payments.post_payments.charges")}
                            />
                        </div>

                        <div className="col-3">&nbsp;</div>

                        <div className="col-3 justify-right" style={{ marginTop: 25 }}>
                            {showResetButton ?
                                <CommonButton
                                    variant='outlined'
                                    label='Reset'
                                    onClick={() => onReset()}
                                    style={{ width: 'auto' }}
                                /> : null}
                            <button
                                type="button"
                                onClick={() => openSearchModal()}
                                className="btn btn-primary-blue btn-common-height35"
                                id="hidingAdvancedOptionSearch"
                                style={{ display: "none" }}
                            >
                                {i18n.t(
                                    "payments.post_payments.insurancePayment.search"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <Divider style={{ marginLeft: -20, marginRight: -20, marginTop: 20 }} />
            </div>
            <div className="">
                <CustomizedDialogs
                    modalId="longWidth"
                    header={header}
                    showModal={searchModalOpen}
                    setShowModalWindow={() => dispatch(hideModalWindow({ key: 'searchModalOpen', open: false }))}
                    resetForm={() => resetFormSearch()}
                >
                    <div className="row">
                        <div className="col-3">
                            <SelectInput
                                selectOptionRemove={true}
                                defaultValues={defaultSearchOption}
                                data={CLAIM_SEARCH_OPTIONS}
                                name="searchOption"
                                value={searchOption}
                                onValueChange={(e) => onHandleChangeSearchOption(e)}
                                label={i18n.t(
                                    "payments.post_payments.insurancePayment.searchResults"
                                )}
                            />
                        </div>
                        <div className="col-3">
                            <TextInput
                                type="text"
                                name="searchText"
                                value={searchText}
                                onValueChange={onHandleChangeSearchOption}
                                label={i18n.t(
                                    "payments.post_payments.insurancePayment.search"
                                )}
                            />
                        </div>
                        <div className="padding-top15">
                            <button
                                type="button"
                                onClick={() => onClickSearchClaim()}
                                className="btn btn-primary-blue margin-left12 margin-top10"
                            >
                                {i18n.t(
                                    "payments.post_payments.insurancePayment.search"
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="margin-top5">
                        <table className="table">
                            <thead>
                                <tr>
                                    {claimListHeadings.map((item, index) => {
                                        return <th key={index}>{item}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {(!patientClaimsData || patientClaimsData.length === 0) && (
                                    <tr>
                                        <td align="center" colSpan={13}>
                                            {i18n.t("commons.noRecords")}{" "}
                                        </td>
                                    </tr>
                                )}
                                {patientClaimsData.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td align="center">
                                                <Link
                                                    to="#"
                                                    className="advancedSearch"
                                                    onClick={(e) => postClaim(e, item.id)}
                                                >
                                                    {i18n.t(
                                                        "payments.post_payments.insurancePayment.table1.post"
                                                    )}
                                                </Link>
                                            </td>
                                            <td align="left">{item.custom_claim_id}</td>
                                            <td align="left">{item.patient_name}</td>
                                            <td align="left">{item.custom_patient_id}</td>
                                            <td align="left">{item.billing_provider}</td>
                                            <td align="center">
                                                {item.service_from_date
                                                    ? CommonDateFormat(item.service_from_date)
                                                    : ""}
                                            </td>
                                            <td>{item.submitted}</td>
                                            <td className="small" align="right">
                                                {currencyFormat(item.charges)}
                                            </td>
                                            <td className="small" align="right">
                                                {currencyFormat(item.payments)}
                                            </td>
                                            <td className="small" align="right">
                                                {currencyFormat(item.adjustments)}
                                            </td>
                                            <td className="small" align="right">
                                                {currencyFormat(item.balance)}
                                            </td>
                                            <td align="left">{item?.resp_payer?.name || 'Paid'}</td>
                                            <td align="left">{item.claim_status}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </CustomizedDialogs>
            </div>
        </>
    )
}

export default ClaimSearchComponent;